'use strict'

exports.messages = {
  NOT_STARTED_YET: 'The libp2p node is not started yet',
  DHT_DISABLED: 'DHT is not available',
  CONN_ENCRYPTION_REQUIRED: 'At least one connection encryption module is required',
  NOT_FOUND: 'Not found'
}

exports.codes = {
  DHT_DISABLED: 'ERR_DHT_DISABLED',
  PUBSUB_NOT_STARTED: 'ERR_PUBSUB_NOT_STARTED',
  DHT_NOT_STARTED: 'ERR_DHT_NOT_STARTED',
  CONN_ENCRYPTION_REQUIRED: 'ERR_CONN_ENCRYPTION_REQUIRED',
  ERR_INVALID_PROTOCOLS_FOR_STREAM: 'ERR_INVALID_PROTOCOLS_FOR_STREAM',
  ERR_CONNECTION_ENDED: 'ERR_CONNECTION_ENDED',
  ERR_CONNECTION_FAILED: 'ERR_CONNECTION_FAILED',
  ERR_NODE_NOT_STARTED: 'ERR_NODE_NOT_STARTED',
  ERR_ALREADY_ABORTED: 'ERR_ALREADY_ABORTED',
  ERR_TOO_MANY_ADDRESSES: 'ERR_TOO_MANY_ADDRESSES',
  ERR_NO_VALID_ADDRESSES: 'ERR_NO_VALID_ADDRESSES',
  ERR_RELAYED_DIAL: 'ERR_RELAYED_DIAL',
  ERR_DIALED_SELF: 'ERR_DIALED_SELF',
  ERR_DISCOVERED_SELF: 'ERR_DISCOVERED_SELF',
  ERR_DUPLICATE_TRANSPORT: 'ERR_DUPLICATE_TRANSPORT',
  ERR_ENCRYPTION_FAILED: 'ERR_ENCRYPTION_FAILED',
  ERR_HOP_REQUEST_FAILED: 'ERR_HOP_REQUEST_FAILED',
  ERR_INVALID_KEY: 'ERR_INVALID_KEY',
  ERR_INVALID_MESSAGE: 'ERR_INVALID_MESSAGE',
  ERR_INVALID_PARAMETERS: 'ERR_INVALID_PARAMETERS',
  ERR_INVALID_PEER: 'ERR_INVALID_PEER',
  ERR_MUXER_UNAVAILABLE: 'ERR_MUXER_UNAVAILABLE',
  ERR_NOT_FOUND: 'ERR_NOT_FOUND',
  ERR_TIMEOUT: 'ERR_TIMEOUT',
  ERR_TRANSPORT_UNAVAILABLE: 'ERR_TRANSPORT_UNAVAILABLE',
  ERR_TRANSPORT_DIAL_FAILED: 'ERR_TRANSPORT_DIAL_FAILED',
  ERR_UNSUPPORTED_PROTOCOL: 'ERR_UNSUPPORTED_PROTOCOL',
  ERR_INVALID_MULTIADDR: 'ERR_INVALID_MULTIADDR',
  ERR_SIGNATURE_NOT_VALID: 'ERR_SIGNATURE_NOT_VALID'
}
