"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Uint8ArrayEquals = void 0;
function Uint8ArrayEquals(a, b) {
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}
exports.Uint8ArrayEquals = Uint8ArrayEquals;
