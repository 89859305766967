{
  "name": "libp2p",
  "version": "0.34.0",
  "description": "JavaScript implementation of libp2p, a modular peer to peer network stack",
  "leadMaintainer": "Jacob Heun <jacobheun@gmail.com>",
  "main": "src/index.js",
  "types": "dist/src/index.d.ts",
  "typesVersions": {
    "*": {
      "src/*": [
        "dist/src/*",
        "dist/src/*/index"
      ]
    }
  },
  "files": [
    "dist",
    "src"
  ],
  "scripts": {
    "lint": "aegir lint",
    "build": "aegir build",
    "build:proto": "npm run build:proto:circuit && npm run build:proto:identify && npm run build:proto:plaintext && npm run build:proto:address-book && npm run build:proto:proto-book && npm run build:proto:peer-record && npm run build:proto:envelope",
    "build:proto:circuit": "pbjs -t static-module -w commonjs -r libp2p-circuit --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/circuit/protocol/index.js ./src/circuit/protocol/index.proto",
    "build:proto:identify": "pbjs -t static-module -w commonjs -r libp2p-identify --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/identify/message.js ./src/identify/message.proto",
    "build:proto:plaintext": "pbjs -t static-module -w commonjs -r libp2p-plaintext --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/insecure/proto.js ./src/insecure/proto.proto",
    "build:proto:address-book": "pbjs -t static-module -w commonjs -r libp2p-address-book --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/peer-store/persistent/pb/address-book.js ./src/peer-store/persistent/pb/address-book.proto",
    "build:proto:proto-book": "pbjs -t static-module -w commonjs -r libp2p-proto-book --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/peer-store/persistent/pb/proto-book.js ./src/peer-store/persistent/pb/proto-book.proto",
    "build:proto:peer-record": "pbjs -t static-module -w commonjs -r libp2p-peer-record --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/record/peer-record/peer-record.js ./src/record/peer-record/peer-record.proto",
    "build:proto:envelope": "pbjs -t static-module -w commonjs -r libp2p-envelope --force-number --no-verify --no-delimited --no-create --no-beautify --no-defaults --lint eslint-disable -o src/record/envelope/envelope.js ./src/record/envelope/envelope.proto",
    "build:proto-types": "npm run build:proto-types:circuit && npm run build:proto-types:identify && npm run build:proto-types:plaintext && npm run build:proto-types:address-book && npm run build:proto-types:proto-book && npm run build:proto-types:peer-record && npm run build:proto-types:envelope",
    "build:proto-types:circuit": "pbts -o src/circuit/protocol/index.d.ts src/circuit/protocol/index.js",
    "build:proto-types:identify": "pbts -o src/identify/message.d.ts src/identify/message.js",
    "build:proto-types:plaintext": "pbts -o src/insecure/proto.d.ts src/insecure/proto.js",
    "build:proto-types:address-book": "pbts -o src/peer-store/persistent/pb/address-book.d.ts src/peer-store/persistent/pb/address-book.js",
    "build:proto-types:proto-book": "pbts -o src/peer-store/persistent/pb/proto-book.d.ts src/peer-store/persistent/pb/proto-book.js",
    "build:proto-types:peer-record": "pbts -o src/record/peer-record/peer-record.d.ts src/record/peer-record/peer-record.js",
    "build:proto-types:envelope": "pbts -o src/record/envelope/envelope.d.ts src/record/envelope/envelope.js",
    "test": "aegir test",
    "test:ts": "aegir build --no-bundle && npm run test --prefix test/ts-use",
    "test:node": "aegir test -t node -f \"./test/**/*.{node,spec}.js\"",
    "test:browser": "aegir test -t browser",
    "test:examples": "cd examples && npm run test:all",
    "test:interop": "LIBP2P_JS=$PWD npx aegir test -t node -f ./node_modules/libp2p-interop/test/*",
    "prepare": "aegir build --no-bundle",
    "release": "aegir release -t node -t browser",
    "release-minor": "aegir release --type minor -t node -t browser",
    "release-major": "aegir release --type major -t node -t browser",
    "coverage": "nyc --reporter=text --reporter=lcov npm run test:node"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/libp2p/js-libp2p.git"
  },
  "keywords": [
    "libp2p",
    "network",
    "p2p",
    "peer",
    "peer-to-peer",
    "IPFS"
  ],
  "bugs": {
    "url": "https://github.com/libp2p/js-libp2p/issues"
  },
  "homepage": "https://libp2p.io",
  "license": "MIT",
  "engines": {
    "node": ">=14.0.0"
  },
  "browser": {
    "@motrix/nat-api": false
  },
  "eslintConfig": {
    "extends": "ipfs",
    "ignorePatterns": [
      "!.aegir.js",
      "test/ts-use",
      "*.d.ts"
    ]
  },
  "dependencies": {
    "abortable-iterator": "^3.0.0",
    "@motrix/nat-api": "^0.3.1",
    "@vascosantos/moving-average": "^1.1.0",
    "abort-controller": "^3.0.0",
    "aggregate-error": "^3.1.0",
    "any-signal": "^2.1.1",
    "bignumber.js": "^9.0.1",
    "class-is": "^1.1.0",
    "debug": "^4.3.1",
    "err-code": "^3.0.0",
    "es6-promisify": "^7.0.0",
    "events": "^3.3.0",
    "hashlru": "^2.3.0",
    "interface-datastore": "^6.0.2",
    "it-all": "^1.0.4",
    "it-buffer": "^0.1.2",
    "it-drain": "^1.0.3",
    "it-filter": "^1.0.1",
    "it-first": "^1.0.4",
    "it-handshake": "^2.0.0",
    "it-length-prefixed": "^5.0.2",
    "it-map": "^1.0.4",
    "it-merge": "^1.0.0",
    "it-pipe": "^1.1.0",
    "it-take": "^1.0.0",
    "libp2p-crypto": "^0.19.4",
    "libp2p-interfaces": "^1.0.0",
    "libp2p-utils": "^0.4.0",
    "mafmt": "^10.0.0",
    "merge-options": "^3.0.4",
    "multiaddr": "^10.0.0",
    "multiformats": "^9.0.0",
    "multistream-select": "^2.0.0",
    "mutable-proxy": "^1.0.0",
    "node-forge": "^0.10.0",
    "p-any": "^3.0.0",
    "p-fifo": "^1.0.0",
    "p-retry": "^4.4.0",
    "p-settle": "^4.1.1",
    "peer-id": "^0.15.0",
    "private-ip": "^2.1.0",
    "protobufjs": "^6.10.2",
    "retimer": "^3.0.0",
    "sanitize-filename": "^1.6.3",
    "set-delayed-interval": "^1.0.0",
    "streaming-iterables": "^6.0.0",
    "timeout-abort-controller": "^1.1.1",
    "uint8arrays": "^3.0.0",
    "varint": "^6.0.0",
    "wherearewe": "^1.0.0",
    "xsalsa20": "^1.1.0"
  },
  "devDependencies": {
    "@chainsafe/libp2p-noise": "^4.0.0",
    "@nodeutils/defaults-deep": "^1.1.0",
    "@types/es6-promisify": "^6.0.0",
    "@types/node": "^16.0.1",
    "@types/node-forge": "^0.10.1",
    "@types/varint": "^6.0.0",
    "aegir": "^36.0.0",
    "buffer": "^6.0.3",
    "datastore-core": "^6.0.7",
    "delay": "^5.0.0",
    "into-stream": "^7.0.0",
    "ipfs-http-client": "^52.0.2",
    "it-concat": "^2.0.0",
    "it-pair": "^1.0.0",
    "it-pushable": "^1.4.0",
    "libp2p": ".",
    "libp2p-bootstrap": "^0.13.0",
    "libp2p-delegated-content-routing": "^0.11.0",
    "libp2p-delegated-peer-routing": "^0.10.0",
    "libp2p-floodsub": "^0.27.0",
    "libp2p-gossipsub": "^0.11.0",
    "libp2p-interfaces-compliance-tests": "^1.0.0",
    "libp2p-interop": "^0.5.0",
    "libp2p-kad-dht": "^0.26.5",
    "libp2p-mdns": "^0.17.0",
    "libp2p-mplex": "^0.10.1",
    "libp2p-tcp": "^0.17.0",
    "libp2p-webrtc-star": "^0.23.0",
    "libp2p-websockets": "^0.16.0",
    "nock": "^13.0.3",
    "p-defer": "^3.0.0",
    "p-times": "^3.0.0",
    "p-wait-for": "^3.2.0",
    "rimraf": "^3.0.2",
    "sinon": "^11.1.1",
    "util": "^0.12.3"
  },
  "contributors": [
    "Vasco Santos <vasco.santos@moxy.studio>",
    "David Dias <daviddias.p@gmail.com>",
    "Jacob Heun <jacobheun@gmail.com>",
    "Alex Potsides <alex@achingbrain.net>",
    "Alan Shaw <alan@tableflip.io>",
    "Cayman <caymannava@gmail.com>",
    "Pedro Teixeira <i@pgte.me>",
    "Friedel Ziegelmayer <dignifiedquire@gmail.com>",
    "Maciej Krüger <mkg20001@gmail.com>",
    "Hugo Dias <mail@hugodias.me>",
    "Chris Dostert <chrisdostert@users.noreply.github.com>",
    "dirkmc <dirkmdev@gmail.com>",
    "Volker Mische <volker.mische@gmail.com>",
    "zeim839 <50573884+zeim839@users.noreply.github.com>",
    "Richard Littauer <richard.littauer@gmail.com>",
    "a1300 <matthias-knopp@gmx.net>",
    "Ryan Bell <ryan@piing.net>",
    "Elven <mon.samuel@qq.com>",
    "ᴠɪᴄᴛᴏʀ ʙᴊᴇʟᴋʜᴏʟᴍ <victorbjelkholm@gmail.com>",
    "Thomas Eizinger <thomas@eizinger.io>",
    "Robert Kiel <robert.kiel@hoprnet.org>",
    "Andrew Nesbitt <andrewnez@gmail.com>",
    "Samlior <samlior@foxmail.com>",
    "acolytec3 <17355484+acolytec3@users.noreply.github.com>",
    "Franck Royer <franck@royer.one>",
    "Giovanni T. Parra <fiatjaf@gmail.com>",
    "Didrik Nordström <didrik.nordstrom@gmail.com>",
    "RasmusErik Voel Jensen <github@solsort.com>",
    "Smite Chow <xiaopengyou@live.com>",
    "Soeren <nikorpoulsen@gmail.com>",
    "Sönke Hahn <soenkehahn@gmail.com>",
    "TJKoury <TJKoury@gmail.com>",
    "TheStarBoys <41286328+TheStarBoys@users.noreply.github.com>",
    "Tiago Alves <alvesjtiago@gmail.com>",
    "XiaoZhang <zxinmyth@gmail.com>",
    "Yusef Napora <yusef@napora.org>",
    "Zane Starr <zcstarr@gmail.com>",
    "ebinks <elizabethjbinks@gmail.com>",
    "Aditya Bose <13054902+adbose@users.noreply.github.com>",
    "isan_rivkin <isanrivkin@gmail.com>",
    "mayerwin <mayerwin@users.noreply.github.com>",
    "mcclure <andi.m.mcclure@gmail.com>",
    "patrickwoodhead <91056047+patrickwoodhead@users.noreply.github.com>",
    "phillmac <phillmac@users.noreply.github.com>",
    "robertkiel <robert.kiel@validitylabs.org>",
    "shresthagrawal <34920931+shresthagrawal@users.noreply.github.com>",
    "swedneck <40505480+swedneck@users.noreply.github.com>",
    "greenSnot <greenSnot@users.noreply.github.com>",
    "Alan Smithee <ggnore.alan.smithee@gmail.com>",
    "Aleksei <vozhdb@gmail.com>",
    "Bernd Strehl <bernd.strehl@gmail.com>",
    "Chris Bratlien <chrisbratlien@gmail.com>",
    "Cindy Wu <ciindy.wu@gmail.com>",
    "Daijiro Wachi <daijiro.wachi@gmail.com>",
    "Diogo Silva <fsdiogo@gmail.com>",
    "Dmitriy Ryajov <dryajov@gmail.com>",
    "Ethan Lam <elmemphis2000@gmail.com>",
    "Fei Liu <liu.feiwood@gmail.com>",
    "Felipe Martins <felipebrasil93@gmail.com>",
    "Florian-Merle <florian.david.merle@gmail.com>",
    "Francis Gulotta <wizard@roborooter.com>",
    "Guy Sviry <32539816+guysv@users.noreply.github.com>",
    "Henrique Dias <hacdias@gmail.com>",
    "Irakli Gozalishvili <rfobic@gmail.com>",
    "Joel Gustafson <joelg@mit.edu>",
    "John Rees <johnrees@users.noreply.github.com>",
    "João Santos <joaosantos15@users.noreply.github.com>",
    "Julien Bouquillon <contact@revolunet.com>",
    "Kevin Kwok <antimatter15@gmail.com>",
    "Kevin Lacker <lacker@gmail.com>",
    "Lars Gierth <lgierth@users.noreply.github.com>",
    "Leask Wong <i@leaskh.com>",
    "Marcin Tojek <mtojek@users.noreply.github.com>",
    "Michael Burns <5170+mburns@users.noreply.github.com>",
    "Miguel Mota <miguelmota2@gmail.com>",
    "Nuno Nogueira <nunofmn@gmail.com>",
    "Philipp Muens <raute1337@gmx.de>"
  ]
}
